import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 width="100%" viewBox="0 0 180 180" >
<path fill="#FEFEFD" opacity="1.000000" stroke="none" 
	d="
M112.000000,181.000000 
	C74.666672,181.000000 37.833336,181.000000 1.000005,181.000000 
	C1.000003,121.000008 1.000003,61.000011 1.000002,1.000013 
	C60.999989,1.000009 120.999977,1.000009 180.999969,1.000004 
	C180.999985,60.999985 180.999985,120.999969 181.000000,180.999969 
	C158.166672,181.000000 135.333328,181.000000 112.000000,181.000000 
M108.199089,167.069641 
	C120.187904,167.069641 132.176712,167.069641 145.044571,167.069641 
	C131.398514,150.355164 118.563911,134.634598 105.562195,118.709343 
	C116.766640,104.057281 127.782700,89.651566 139.321396,74.562393 
	C128.684570,74.562393 119.198296,74.898239 109.751465,74.432991 
	C104.322060,74.165588 100.800308,75.740311 97.858154,80.373398 
	C94.452972,85.735603 90.241409,90.585739 86.106621,96.017220 
	C81.200600,89.458954 76.894630,83.557968 72.400711,77.803757 
	C71.359772,76.470886 69.696709,74.748611 68.286652,74.717598 
	C56.883621,74.466743 45.472652,74.576874 33.027252,74.576874 
	C44.618839,89.726830 55.596962,104.075005 66.787659,118.701004 
	C53.751202,134.690628 40.870770,150.488892 27.335613,167.090195 
	C38.488205,167.090195 48.591221,166.748032 58.654819,167.229568 
	C63.961220,167.483459 67.152634,165.713699 70.133568,161.454056 
	C75.037651,154.446274 80.624634,147.916382 86.158287,140.887054 
	C88.219978,143.452713 89.768906,145.401215 91.340034,147.331635 
	C96.674225,153.885696 102.016273,160.433365 108.199089,167.069641 
M144.193542,49.936413 
	C144.035294,45.441761 144.943512,40.575661 143.530258,36.518131 
	C138.036179,20.744242 123.549438,10.896276 106.784874,10.828581 
	C86.136322,10.745202 65.487190,10.797997 44.838337,10.813844 
	C43.389420,10.814956 41.940639,10.996899 40.241508,11.111657 
	C40.241508,24.141672 40.241508,36.867622 40.241508,50.144703 
	C74.766861,50.144703 109.013947,50.144703 144.193542,49.936413 
z"/>
<path fill="#030203" opacity="1.000000" stroke="none" 
	d="
M107.777206,167.026550 
	C102.016273,160.433365 96.674225,153.885696 91.340034,147.331635 
	C89.768906,145.401215 88.219978,143.452713 86.158287,140.887054 
	C80.624634,147.916382 75.037651,154.446274 70.133568,161.454056 
	C67.152634,165.713699 63.961220,167.483459 58.654819,167.229568 
	C48.591221,166.748032 38.488205,167.090195 27.335613,167.090195 
	C40.870770,150.488892 53.751202,134.690628 66.787659,118.701004 
	C55.596962,104.075005 44.618839,89.726830 33.027252,74.576874 
	C45.472652,74.576874 56.883621,74.466743 68.286652,74.717598 
	C69.696709,74.748611 71.359772,76.470886 72.400711,77.803757 
	C76.894630,83.557968 81.200600,89.458954 86.106621,96.017220 
	C90.241409,90.585739 94.452972,85.735603 97.858154,80.373398 
	C100.800308,75.740311 104.322060,74.165588 109.751465,74.432991 
	C119.198296,74.898239 128.684570,74.562393 139.321396,74.562393 
	C127.782700,89.651566 116.766640,104.057281 105.562195,118.709343 
	C118.563911,134.634598 131.398514,150.355164 145.044571,167.069641 
	C132.176712,167.069641 120.187904,167.069641 107.777206,167.026550 
z"/>
<path fill="#FFBD59" opacity="1.000000" stroke="none" 
	d="
M143.727295,50.040558 
	C109.013947,50.144703 74.766861,50.144703 40.241508,50.144703 
	C40.241508,36.867622 40.241508,24.141672 40.241508,11.111657 
	C41.940639,10.996899 43.389420,10.814956 44.838337,10.813844 
	C65.487190,10.797997 86.136322,10.745202 106.784874,10.828581 
	C123.549438,10.896276 138.036179,20.744242 143.530258,36.518131 
	C144.943512,40.575661 144.035294,45.441761 143.727295,50.040558 
z"/>
</svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
