export const table_type = [
  { value: '0101 Venta Interna', label: '0101 Venta Interna' },
  { value: '0112 Venta Interna - Sustenta Gastos Deducibles Persona Natural', label: '0112 Venta Interna - Sustenta Gastos Deducibles Persona Natural' },
  { value: '0113 Venta Interna-NRUS solo Boletas', label: '0113 Venta Interna-NRUS solo Boletas' },
  { value: '0200 Exportación de Bienes', label: '0200 Exportación de Bienes' },
  {
    value:
      '0201 Exportación de Servicios Prestación servicios realizados íntegramente en el país',
    label: '0201 Exportación de Servicios Prestación servicios realizados íntegramente en el país',
  },
  {
    value: '0202 Exportación de Servicios Prestación de servicios de hospedaje No Domiciliado',
    label: '0202 Exportación de Servicios Prestación de servicios de hospedaje No Domiciliado',
  },
  { value: '0203 Exportación de Servicios Transporte de navieras', label: '0203 Exportación de Servicios – Transporte de navieras' },
  {
    value: '0204 Exportación de Servicios Servicios a naves y aeronaves de bandera extranjera',
    label: '0204 Exportación de Servicios Servicios a naves y aeronaves de bandera extranjera',
  },
  {
    value: '0205 Exportación de Servicios - Servicios que conformen un Paquete Turístico',
    label: '0205 Exportación de Servicios - Servicios que conformen un Paquete Turístico',
  },
  {
    value: '0206 Exportación de Servicios Servicios complementarios al transporte de carga',
    label: '0206 Exportación de Servicios Servicios complementarios al transporte de carga',
  },
  {
    value:
      '0207 Exportación de Servicios Suministro de energía eléctrica a favor de sujetos domiciliados en ZED',
    label: '0207 Exportación de Servicios Suministro de energía eléctrica a favor de sujetos domiciliados en ZED',
  },
  {
    value:
      '0208 Exportación de Servicios Prestación servicios realizados parcialmente en el extranjero',
    label: '0208 Exportación de Servicios Prestación servicios realizados parcialmente en el extranjero',
  },
  {
    value: '0301 Operaciones con Carta de porte aéreo (emitidas en el ámbito nacional)',
    label: '0301 Operaciones con Carta de porte aéreo (emitidas en el ámbito nacional)',
  },
  { value: '0302 Operaciones de Transporte ferroviario de pasajeros', label: '0302 Operaciones de Transporte ferroviario de pasajeros' },
  { value: '0303 Operaciones de Pago de regalía petrolera', label: '0303 Operaciones de Pago de regalía petrolera' },
  { value: '0401 Ventas no domiciliados que no califican como exportación', label: '0401 Ventas no domiciliados que no califican como exportación' },
  { value: '0501 Compra interna Liquidacion de Compra', label: '0501 Compra interna Liquidacion de Compra' },
  { value: '0502 Anticipos Liquidacion de Compra', label: '0502 Anticipos Liquidacion de Compra' },
  { value: '0503 Compra de oro Liquidacion de Compra', label: '0503 Compra de oro Liquidacion de Compra' },
  { value: '1001 Operación Sujeta a Detracción', label: '1001 Operación Sujeta a Detracción' },
  { value: '1002 Operación Sujeta a Detracción- Recursos Hidrobiológicos', label: '1002 Operación Sujeta a Detracción- Recursos Hidrobiológicos' },
  {
    value: '1003 Operación Sujeta a Detracción- Servicios de Transporte Pasajeros',
    label: '1003 Operación Sujeta a Detracción- Servicios de Transporte Pasajeros',
  },
  { value: '1004 Operación Sujeta a Detracción- Servicios de Transporte Carga', label: '1004 Operación Sujeta a Detracción- Servicios de Transporte Carga' },
  { value: '2001 Operación Sujeta a Percepción', label: '2001 Operación Sujeta a Percepción' },
  { value: '2002 Operación sujeta a Retención de Renta de segunda categoría', label: '2002 Operación sujeta a Retención de Renta de segunda categoría' },
  { value: '2100 Créditos a empresas', label: '2100 Créditos a empresas' },
  { value: '2101 Créditos de consumo revolvente', label: '2101 Créditos de consumo revolvente' },
  { value: '2102 Créditos de consumo no revolvente', label: '2102 Créditos de consumo no revolvente' },
  {
    value:
      '2103 Otras operaciones no gravadas - Empresas del sistema financiero y cooperativas de ahorro y crédito no autorizadas a captar recursos del público',
    label: '2103 Otras operaciones no gravadas - Empresas del sistema financiero y cooperativas de ahorro y crédito no autorizadas a captar recursos del público',
  },
  {
    value: '2104 Otras operaciones no gravadas - Empresas del sistema de seguros',
    label: '2104 Otras operaciones no gravadas - Empresas del sistema de seguros',
  },
  { value: '2105 Comprobante emitido por AFP solo Boletas', label: '2105 Comprobante emitido por AFP solo Boletas' },
  { value: '2106 Venta Nacional a Turistas - Tax Free', label: '2106 Venta Nacional a Turistas - Tax Free' },
];

export const table_affectation = [
  { value: '10 Gravado - Operación Onerosa', label: '10 Gravado - Operación Onerosa' },
       { value: '11 Gravado Retiro por premio', label: '11 Gravado Retiro por premio' },
       { value: '12 Gravado Retiro por donación', label: '12 Gravado Retiro por donación' },
       { value: '13 Gravado Retiro ', label: '13 Gravado Retiro ' },
       { value: '14 Gravado Retiro por publicidad', label: '14 Gravado Retiro por publicidad' },
       { value: '15 Gravado Bonificaciones', label: '15 Gravado Bonificaciones' },
       { value: '16 Gravado Retiro por entrega a trabajadores', label: '16 Gravado Retiro por entrega a trabajadores' },
       { value: '17 Gravado - IVAP', label: '17 Gravado - IVAP' },
       { value: '20 Exonerado - Operación Onerosa', label: '20 Exonerado - Operación Onerosa' },
       { value: '21 Exonerado - Transferencia gratuita', label: '21 Exonerado - Transferencia gratuita' },
       { value: '30 Inafecto - Operación Onerosa', label: '30 Inafecto - Operación Onerosa' },
       { value: '31 Inafecto Retiro por Bonificación', label: '31 Inafecto Retiro por Bonificación' },
       { value: '32 Inafecto Retiro', label: '32 Inafecto Retiro' },
       { value: '33 Inafecto Retiro por Muestras Médicas', label: '33 Inafecto Retiro por Muestras Médicas' },
       { value: '34 Inafecto - Retiro por Convenio Colectivo', label: '34 Inafecto - Retiro por Convenio Colectivo' },
       { value: '35 Inafecto Retiro por premio', label: '35 Inafecto Retiro por premio' },
       { value: '36 Inafecto - Retiro por publicidad', label: '36 Inafecto - Retiro por publicidad' },
       { value: '37 Inafecto - Transferencia gratuita', label: '37 Inafecto - Transferencia gratuita' },
       { value: '40 Exportación de Bienes o Servicios', label: '40 Exportación de Bienes o Servicios' },

];

export const _roles = [
  'Admin',
  'Analista',
  'Invitado',
  'Soporte',
  'Supervisor',
  'Usuario',
];

