import { useContext, useState, useEffect, useCallback } from 'react';

import { m } from 'framer-motion';
// @mui
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
// utils
import { fToNow } from 'src/utils/format-time';

// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ICompanies } from 'src/types/companies';

import { AuthContext } from 'src/auth/context/jwt/auth-context';
import { useDispatch } from 'src/redux/store';
import { setIden, setReuc , setRaso, setRole  } from 'src/redux/slices/global';
// ----------------------------------------------------------------------

export default function CompaniesPopover() {
  const dispatch = useDispatch();
  const popover = usePopover();

  const { user } = useContext(AuthContext);

  console.log(456);
  console.log({ user });

  const companies = user?.company || [];

  useEffect(() => {
    const firstEmpresIden = user?.company[0]?.empres_iden || null;
    const firstEmpresReuc = user?.company[0]?.empres_reuc || null;
    const firstEmpresRaso = user?.company[0]?.empres_raso || null;
    const firstEmpresRole = user?.company[0]?.companyrole || null;
    dispatch(setIden(firstEmpresIden));
    dispatch(setReuc(firstEmpresReuc));
    dispatch(setRaso(firstEmpresRaso));
    dispatch(setRole(firstEmpresRole));
  }, [dispatch, user]);

  const handleItemClick = useCallback(
    (empresIden: string,empresReuc: string,empresRaso: string,empresRole: string) => {
      dispatch(setIden(empresIden));
      dispatch(setReuc(empresReuc));
      dispatch(setRaso(empresRaso));
      dispatch(setRole(empresRole));
    },
    [dispatch]
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={popover.open ? 'inherit' : 'default'}
        onClick={popover.onOpen}
        sx={{
          ...(popover.open && {
            bgcolor: (theme) => theme.palette.action.selected,
          }),
        }}
      >
        <Iconify icon="solar:box-minimalistic-bold" width={24} />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 400 }}>
        <Typography variant="h6" sx={{ p: 1.5 }}>
          Empresas <Typography component="span">({companies.length})</Typography>
        </Typography>

        <Scrollbar sx={{ height: 400 }}>
          {companies.map((company: ICompanies) => (
            <MenuItem
              key={company.empres_iden}
              sx={{ p: 1 }}
              onClick={() => handleItemClick(company.empres_iden,company.empres_reuc,company.empres_raso,company.companyrole)}
            >
              <Iconify icon="eva:cube-fill" width={32} sx={{ ml: 0.5 }} />
              <ListItemText
  primary={company.empres_iden}
  secondary={`${company.empres_reuc} ${company.empres_raso}\n${company.companyrole}`}
  primaryTypographyProps={{ variant: 'subtitle2' }}
  secondaryTypographyProps={{
    variant: 'caption',
    color: 'text.disabled',
    sx: { whiteSpace: 'pre-line' }, // Aplicamos el estilo para permitir saltos de línea
  }}
/>
            
            </MenuItem>
          ))}
        </Scrollbar>
      </CustomPopover>
    </>
  );
}
