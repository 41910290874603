import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('general'),
        items: [
          { title: t('app'), path: paths.dashboard.root, icon: ICONS.dashboard },
          { title: t('ecommerce'), path: paths.dashboard.general.ecommerce, icon: ICONS.ecommerce },

          { title: t('lista de precios'), path: paths.dashboard.pricelist, icon: ICONS.file },
        ],
      },

      {
        subheader: t('modulos'),
        items: [
          {
            title: t('presupuestos'),
            path: paths.dashboard.butged.root,
            icon: ICONS.blog,
            children: [{ title: t('listado'), path: paths.dashboard.butged.list }],
          },
        ],
      },

      {
        subheader: t('reportes'),
        items: [
          {
            title: t('clientes'),
            path: paths.dashboard.user.root,
            icon: ICONS.external,
            children: [
              { title: t('profile'), path: paths.dashboard.user.root },
              { title: t('cards'), path: paths.dashboard.user.cards },
              { title: t('list'), path: paths.dashboard.user.list },
              { title: t('create'), path: paths.dashboard.user.new },
              { title: t('edit'), path: paths.dashboard.user.demo.edit },
              { title: t('account'), path: paths.dashboard.user.account },
            ],
          },
        ],
      },

      {
        subheader: t('tablas'),
        items: [
          {
            title: t('clientes'),
            path: paths.dashboard.customer.root,
            icon: ICONS.user,
            children: [
              { title: t('listado'), path: paths.dashboard.customer.list },
            ],
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('seguridad'),
        items: [
          // USER
          {
            title: t('usuarios'),
            path: paths.dashboard.users.root,
            icon: ICONS.lock,
            children: [
               { title: t('listado'), path: paths.dashboard.users.list },
             ],
          },
          { title: t('Log de Accesos'), path: paths.dashboard.logs.list, icon: ICONS.file },
        ],
      },
    ],
    [t]
  );

  return data;
}
