import { configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { rootReducer, rootPersistConfig } from './root-reducer';

// ----------------------------------------------------------------------

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export const useDispatch = () => useAppDispatch<AppDispatch>();

let period: string = '';
let doc: string = '';
let cigv: string='SI';


export function getPeriod_Store(): string {
  return period;
}

export function changePeriod_Store(newvalue: string): void {
  period = newvalue;
}

export function getDoc_Store(): string {
  return doc;
}

export function changeDoc_Store(newvalue: string): void {
  doc = newvalue;
}

export function getCIgv_Store(): string {
  return cigv;
}

export function changeCIgv_Store(newvalue: string): void {
  cigv = newvalue;
}
