import { useEffect, useReducer, useCallback, useMemo } from 'react';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const { REACT_APP_HOST_API_KEY } = process.env;

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const config = {
          headers: {
            Authorization: `bearer ${accessToken}`,
          },
        };

      
        const response = await axios.get(`${REACT_APP_HOST_API_KEY}/api/token/auth`, config);

        const { user } = response.data;

        console.log('auth-provider');
        console.log(user);

        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (code: string, email: string, password: string) => {
    const ipifyResponse = await axios.get('https://api.ipify.org?format=json');
    const userIP = ipifyResponse.data.ip;

    const userAgent = navigator.userAgent;
    let osName = 'Unknown';
    let browserName = 'Unknown';

    if (userAgent.indexOf('Win') !== -1) {
      osName = 'Windows';
    } else if (userAgent.indexOf('Mac') !== -1) {
      osName = 'MacOS';
    } else if (userAgent.indexOf('Linux') !== -1) {
      osName = 'Linux';
    } else if (userAgent.indexOf('Android') !== -1) {
      osName = 'Android';
    } else if (userAgent.indexOf('iOS') !== -1) {
      osName = 'iOS';
    }

    if (userAgent.indexOf('Chrome') !== -1) {
      browserName = 'Google Chrome';
    } else if (userAgent.indexOf('Safari') !== -1) {
      browserName = 'Safari';
    } else if (userAgent.indexOf('Firefox') !== -1) {
      browserName = 'Mozilla Firefox';
    } else if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident/') !== -1) {
      browserName = 'Internet Explorer';
    } else if (userAgent.indexOf('Edge') !== -1) {
      browserName = 'Microsoft Edge';
    }

    const data = {
      code,
      email,
      password,
      ip: userIP,
      so: osName,
      nv: browserName,
    };

    const response = await axios.post(`${REACT_APP_HOST_API_KEY}/api/token`, data);

    const { accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: Types.LOGIN,
      payload: {
        user,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const response = await axios.post(API_ENDPOINTS.auth.register, data);

      const { accessToken, user } = response.data;

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user,
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
