import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import { IButgedState } from 'src/types/butged';

// ----------------------------------------------------------------------
const { REACT_APP_HOST_API_KEY } = process.env;

const initialState: IButgedState = {
  butgeds: [],
  butged: null,
  butgedsStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  butgedStatus: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'butged',
  initialState,
  reducers: {
    getButgedsStart(state) {
      state.butgedsStatus.loading = true;
      state.butgedsStatus.empty = false;
      state.butgedsStatus.error = null;
    },
    getButgedsFailure(state, action) {
      state.butgedsStatus.loading = false;
      state.butgedsStatus.empty = false;
      state.butgedsStatus.error = action.payload;
    },
    getButgedsSuccess(state, action) {
      const butgeds = action.payload;
      state.butgeds = butgeds;
      state.butgedsStatus.loading = false;
      state.butgedsStatus.empty = !butgeds.length;
      state.butgedsStatus.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getButgeds(empresa: string, periodo: string, estado: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.getButgedsStart());
    try {
      const response = await axios.get(
        `${REACT_APP_HOST_API_KEY}/api/Cotcab/List/${empresa}/${periodo}/${estado}`,
        {
          headers: {
            Authorization: axios.defaults.headers.common.Authorization,
          },
        }
      );
      dispatch(slice.actions.getButgedsSuccess(response.data.butgeds));
    } catch (error) {
      dispatch(slice.actions.getButgedsFailure(error));
    }
  };
}

export async function ButgedNew(data: any) {
  console.log(data);
  return axios
    .post(`${REACT_APP_HOST_API_KEY}/api/Cotcab/NewData`, data, {
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.data.event)
    .catch((error) => {
      console.error('Error en la petición:', error);
      throw error;
    });
}

export const getdata = async (empresa: string, id: string) => {
  try {
    const response = await axios.get(
      `${REACT_APP_HOST_API_KEY}/api/CotCab/GetData/${empresa}/${id}`,
      {
        headers: {
          Authorization: axios.defaults.headers.common.Authorization,
        },
      }
    );
    const data = { response };
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to fetch service options');
  }
};

export async function ButgedEdit(data: any) {
  return axios
    .post(`${REACT_APP_HOST_API_KEY}/api/Cotcab/EditData`, data, {
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.data.event)
    .catch((error) => {
      console.error('Error en la petición:', error);
      throw error;
    });
}

export async function ButgedDelete(empresa:string, id: string) {
  const res = await axios.post(`${REACT_APP_HOST_API_KEY}/api/Cotcab/Delete/${empresa}/${id}`, null, {
    headers: {
      Authorization: axios.defaults.headers.common.Authorization,
      'Content-Type': 'application/json',
    },
  });
  return res.data.event;
}

export const getPeriods = async (empresa: string) => {
  try {
    const response = await axios.get(`${REACT_APP_HOST_API_KEY}/api/Period/List/${empresa}`, {
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
      },
    });
    const data = { response };
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to fetch service options');
  }
};

export const getDocs = async (empresa: string, modulo: string) => {
  try {
    const response = await axios.get(
      `${REACT_APP_HOST_API_KEY}/api/Tipdoc/List/${empresa}/${modulo}`,
      {
        headers: {
          Authorization: axios.defaults.headers.common.Authorization,
        },
      }
    );
    const data = { response };
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to fetch service options');
  }
};

export function getPdf(empres: string, id: string) {
  axios
    .get(`${REACT_APP_HOST_API_KEY}/api/PDFCotcab/pdf/${empres}/${id}`, {
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    })
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    })
    .catch((error) => {
      console.error(error);
    });
}

export function getAuthorize(empresa: string, id: string, authorized: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.getButgedsStart());
    try {
      const response = await axios.post(
        `${REACT_APP_HOST_API_KEY}/api/Cotcab/Authorize/${empresa}/${id}/${authorized}`,
        {
          headers: {
            Authorization: axios.defaults.headers.common.Authorization,
          },
        }
      );
      dispatch(slice.actions.getButgedsSuccess(response.data.butgeds));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.getButgedsFailure(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export async function getDClprtr(empresa: string, suna: string, tipo: string) {
  try {
    const response = await axios.get(
      `${REACT_APP_HOST_API_KEY}/api/DClprtr/List/${empresa}/${suna}/${tipo}`,
      {
        headers: {
          Authorization: axios.defaults.headers.common.Authorization,
        },
      }
    );
    const { data } = response;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getDVended(empresa: string) {
  try {
    const response = await axios.get(`${REACT_APP_HOST_API_KEY}/api/DVended/List/${empresa}`, {
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
      },
    });
    const { data } = response;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getDForpag() {
  try {
    const response = await axios.get(`${REACT_APP_HOST_API_KEY}/api/DForpag/List`, {
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
      },
    });
    const { data } = response;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getDAlmace(empresa: string) {
  try {
    const response = await axios.get(`${REACT_APP_HOST_API_KEY}/api/DAlmace/List/${empresa}`, {
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
      },
    });
    const { data } = response;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getDProser(empresa: string, moneda: string, lista: string, igv: string) {
  try {
    const response = await axios.get(
      `${REACT_APP_HOST_API_KEY}/api/DProser/List/${empresa}/${moneda}/${lista}/${igv}`,
      {
        headers: {
          Authorization: axios.defaults.headers.common.Authorization,
        },
      }
    );
    const { data } = response;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// ----------------------------------------------------------------------

export async function getTC(empresa: string, fecha: Date) {
  try {
    const year = fecha.getFullYear();
    const month = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const day = fecha.getDate().toString().padStart(2, '0');
    const fechaFormateada = `${year}-${month}-${day}`;

console.log(fechaFormateada);

    const response = await axios.get(
      `${REACT_APP_HOST_API_KEY}/api/TipCam/List/${empresa}/${fechaFormateada}`,
      {
        headers: {
          Authorization: axios.defaults.headers.common.Authorization,
        },
      }
    );
    const { data } = response;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
