import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import { ILogState } from 'src/types/log';

// ----------------------------------------------------------------------
const { REACT_APP_HOST_API_KEY } = process.env;

const initialState: ILogState = {
  logs: [],
  log: null,
  logsStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  logStatus: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'log',
  initialState,
  reducers: {

    getLogsStart(state) {
      state.logsStatus.loading = true;
      state.logsStatus.empty = false;
      state.logsStatus.error = null;
    },
    getLogsFailure(state, action) {
      state.logsStatus.loading = false;
      state.logsStatus.empty = false;
      state.logsStatus.error = action.payload;
    },
    getLogsSuccess(state, action) {
      const logs = action.payload;

      state.logs = logs;

      state.logsStatus.loading = false;
      state.logsStatus.empty = !logs.length;
      state.logsStatus.error = null;
    },

  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getLogs() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.getLogsStart());
    try {
      const response = await axios.get(`${REACT_APP_HOST_API_KEY}/api/Logs/List`);
      console.log(789);
      console.log(response.data);
      dispatch(slice.actions.getLogsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.getLogsFailure(error));
    }
  };
}



