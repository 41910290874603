import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

import { IUserState } from 'src/types/user'; 

// ----------------------------------------------------------------------
const { REACT_APP_HOST_API_KEY } = process.env;




const initialState: IUserState = {
  users: [],
  user: null,
  usersStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  userStatus: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {

    getUsersStart(state) {
      state.usersStatus.loading = true;
      state.usersStatus.empty = false;
      state.usersStatus.error = null;
    },
    getUsersFailure(state, action) {
      state.usersStatus.loading = false;
      state.usersStatus.empty = false;
      state.usersStatus.error = action.payload;
    },
    getUsersSuccess(state, action) {
      const users = action.payload;

      state.users = users;

      state.usersStatus.loading = false;
      state.usersStatus.empty = !users.length;
      state.usersStatus.error = null;
    },

  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getUsers() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.getUsersStart());
    try {
      const response = await axios.get(`${REACT_APP_HOST_API_KEY}/api/Users/List`);
      console.log(789);
      console.log(response.data);
      dispatch(slice.actions.getUsersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.getUsersFailure(error));
    }
  };
}

export async function UserNew(data: any) {


 

  console.log(123);
  console.log(data);

  return axios
    .post(`${REACT_APP_HOST_API_KEY}/api/Users/newdata`, data, {
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.data.event)
    .catch((error) => {
      console.error('Error en la petición:', error);
      throw error; 
    }); 
}

export async function UserUpdate(data: any) {
  const requestData = {
    usuario: 'admin1',
    ...data,
  };
  return axios
    .post(`${REACT_APP_HOST_API_KEY}/api/Clprtr/editdata`, requestData, {
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.data.event)
    .catch((error) => {
      console.error('Error en la petición:', error);
      throw error; 
    });
}

export async function UsersDelete( id: string) {
  const res = await axios.post(`${REACT_APP_HOST_API_KEY}/api/Users/delete/${id}`, null, {
    headers: {
      Authorization: axios.defaults.headers.common.Authorization,
      'Content-Type': 'application/json',
    },
  });
  return res.data.event;
}

export async function GetEmpres() {
  try {
    const response = await axios.get(`${REACT_APP_HOST_API_KEY}/api/Empres/List`, {
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
        'Content-Type': 'application/json',
      },
    });
   
    const { data } = response; 
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function GetUsersCompanies(id: string) {
  try {

console.log(888);
console.log(id);

    const response = await axios.get(`${REACT_APP_HOST_API_KEY}/api/Users/ListUsersCompanies/${id}`, {
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
        'Content-Type': 'application/json',
      },
    });
   
    const { data } = response; 
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function GetSelects(empres_iden: string) {
  try {
    const response = await axios.get(`${REACT_APP_HOST_API_KEY}/api/Users/GetSelects/${empres_iden}`, {
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
        'Content-Type': 'application/json',
      },
    });
   
    const { data } = response; 
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}



