import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import kanbanReducer from './slices/kanban';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar'; 

import pricelistReducer from './slices/pricelist';
import customerReducer from './slices/customer';
import butgedReducer from './slices/butged';
import userReducer from './slices/user';
import logReducer from './slices/log';

import globalReducer from './slices/global';
// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['checkout'],
};

const customerPersistConfig = {
  key: 'customer',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['checkout'],
};

const pricelistPersistConfig = {
  key: 'pricelist',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['checkout'],
};

const butgedPersistConfig = {
  key: 'butged',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['checkout'],
};

const userPersistConfig = {
  key: 'user',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['checkout'],
};

const logPersistConfig = {
  key: 'log',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['checkout'],
};

export const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  calendar: calendarReducer,
  global: globalReducer,
  product: persistReducer(productPersistConfig, productReducer),
  pricelist: persistReducer(pricelistPersistConfig, pricelistReducer),
  customer: persistReducer(customerPersistConfig, customerReducer),
  butged: persistReducer(butgedPersistConfig, butgedReducer),
  user: persistReducer(userPersistConfig, userReducer),
  log: persistReducer(logPersistConfig, logReducer),
});
