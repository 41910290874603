import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import { ICustomerState } from 'src/types/customer';

// ----------------------------------------------------------------------
const { REACT_APP_HOST_API_KEY } = process.env;

const initialState: ICustomerState = {
  customers: [],
  customer: null,
  customersStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  customerStatus: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {

    getCustomersStart(state) {
      state.customersStatus.loading = true;
      state.customersStatus.empty = false;
      state.customersStatus.error = null;
    },
    getCustomersFailure(state, action) {
      state.customersStatus.loading = false;
      state.customersStatus.empty = false;
      state.customersStatus.error = action.payload;
    },
    getCustomersSuccess(state, action) {
      const customers = action.payload;

      state.customers = customers;

      state.customersStatus.loading = false;
      state.customersStatus.empty = !customers.length;
      state.customersStatus.error = null;
    },

  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getCustomers(empres: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.getCustomersStart());
    try {
      const response = await axios.get(`${REACT_APP_HOST_API_KEY}/api/Clprtr/list/${empres}`);
      dispatch(slice.actions.getCustomersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.getCustomersFailure(error));
    }
  };
}

export async function CustomerNew(data: any) {
  const requestData = {
    empresa: '0001',
    usuario: 'admin1',
    ...data,
  };

  console.log(requestData);

  return axios
    .post(`${REACT_APP_HOST_API_KEY}/api/Clprtr/newdata`, requestData, {
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.data.event)
    .catch((error) => {
      console.error('Error en la petición:', error);
      throw error; 
    }); 
}

export async function CustomerUpdate(data: any) {
  const requestData = {
    empresa: '0001',
    usuario: 'admin1',
    ...data,
  };
  return axios
    .post(`${REACT_APP_HOST_API_KEY}/api/Clprtr/editdata`, requestData, {
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.data.event)
    .catch((error) => {
      console.error('Error en la petición:', error);
      throw error; 
    });
}

export async function CustomerDelete(empres: string, id: string) {
  const res = await axios.post(`${REACT_APP_HOST_API_KEY}/api/Clprtr/delete/${empres}/${id}`, null, {
    headers: {
      Authorization: axios.defaults.headers.common.Authorization,
      'Content-Type': 'application/json',
    },
  });
  return res.data.event;
}

export async function GetType() {
  try {
    const response = await axios.get(`${REACT_APP_HOST_API_KEY}/api/Idesun/List`, {
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
      },
    });
    const { data } = response; 
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const GetSunat = async (selectedOption: string, clprtr_reuc: string) => {
  try {
    const response = await axios.get(
      `${REACT_APP_HOST_API_KEY}/api/Sunat/Consult/${selectedOption}/${clprtr_reuc}`,
      {
        headers: {
          Authorization: axios.defaults.headers.common.Authorization,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export async function GetUbigeo() {
  try {
    const response = await axios.get(`${REACT_APP_HOST_API_KEY}/api/Ubigeo/List`, {
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
      },
    });
    const { data } = response;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  } 
}
