import { createSlice } from '@reduxjs/toolkit';

export type GlobalState = {
  iden: string;
  reuc: string;
  raso: string;
  role: string;
};

const initialState: GlobalState = {
  iden: '',
  reuc: '',
  raso: '',
  role: '',
};

const globalslice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIden: (state, action) => {
      state.iden = action.payload;
    },
    setReuc: (state, action) => {
      state.reuc = action.payload;
    },
    setRaso: (state, action) => {
      state.raso = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
  },
});

export const { setIden, setReuc, setRaso, setRole } = globalslice.actions;
export default globalslice.reducer;
