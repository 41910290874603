import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd/MM/yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateDay(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd'; // Utiliza 'dd' para obtener solo el día

  return date ? format(new Date(date), fm) : '';
}


export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd/MM/yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fDateTC(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'yyyy-MM-dd';

  return date ? format(new Date(date), fm) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}
