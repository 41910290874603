import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { IPricelistState } from 'src/types/pricelist';

// ----------------------------------------------------------------------
const { REACT_APP_HOST_API_KEY } = process.env;

const initialState: IPricelistState = {
  pricelists: [],
  pricelist: null,

  pricelistsStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  pricelistStatus: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'pricelist',
  initialState,
  reducers: {

    getPricelistsStart(state) {
      state.pricelistsStatus.loading = true;
      state.pricelistsStatus.empty = false;
      state.pricelistsStatus.error = null;
    },
    getPricelistsFailure(state, action) {
      state.pricelistsStatus.loading = false;
      state.pricelistsStatus.empty = false;
      state.pricelistsStatus.error = action.payload;
    },
    getPricelistsSuccess(state, action) {
      const pricelists = action.payload;
      state.pricelists = pricelists;
      state.pricelistsStatus.loading = false;
      state.pricelistsStatus.empty = !pricelists.length;
      state.pricelistsStatus.error = null;
    },

    getPricelistStart(state) {
      state.pricelistStatus.loading = true;
      state.pricelistStatus.error = null;
    },
    getPricelistFailure(state, action) {
      state.pricelistStatus.loading = false;
      state.pricelistStatus.error = action.payload;
    },
    getPricelistSuccess(state, action) {
      const pricelist = action.payload;
      state.pricelist = pricelist;
      state.pricelistStatus.loading = false;
      state.pricelistStatus.error = null;
    },


  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getList(empres: string,) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.getPricelistStart());
    try {

      const response = await axios.get(`${REACT_APP_HOST_API_KEY}/api/Proser/List/${empres}`, {
        headers: {
          Authorization: axios.defaults.headers.common.Authorization,
        },
      });
      dispatch(slice.actions.getPricelistsSuccess(response.data.lists));
    } catch (error) {
      dispatch(slice.actions.getPricelistsFailure(error));
    }
  };
}

export async function GetStock(empres: string, id: string) {
  try {
    const response = await axios.get(`${REACT_APP_HOST_API_KEY}/api/Stock/listar/${empres}/${id}`, {
      headers: { Authorization: axios.defaults.headers.common.Authorization },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching values:', error);
    throw error;
  }
}

export function GetPdf(empres: string, id: string) {
  axios
    .get(`${REACT_APP_HOST_API_KEY}/api/Inventory/pdf/${empres}/${id}`, {
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    })
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    })
    .catch((error) => {
      console.error(error);
    });
}
